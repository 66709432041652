@font-face {
    font-family: 'Inter Light';
    font-style: normal;
    font-weight: 300;
    src: local('Inter Light'), url('Inter-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter Regular';
    font-style: normal;
    font-weight: 400;
    src: local('Inter Regular'), url('Inter-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter Medium';
    font-style: normal;
    font-weight: 500;
    src: local('Inter Medium'), url('Inter-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter SemiBold';
    font-style: normal;
    font-weight: 600;
    src: local('Inter SemiBold'), url('Inter-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter Bold';
    font-style: normal;
    font-weight: 700;
    src: local('Inter Bold'), url('Inter-Bold.ttf') format('truetype');
}
