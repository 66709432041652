/* Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Common CSS Start */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  @apply m-0 box-border p-0 font-interRegular text-base outline-none;
}

body {
  @apply antialiased;
}

html {
  @apply scroll-smooth;
}

img {
  @apply max-w-full;
}

ul {
  @apply list-none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* Scrollbar styling */

::-webkit-scrollbar-track {
  @apply bg-[#ededed];
}

::-webkit-scrollbar {
  @apply w-1.5 h-1.5;
}

::-webkit-scrollbar-thumb {
  @apply rounded-md bg-[#d2d2d2];
}

.custom-cursor-pointer::-webkit-scrollbar-thumb {
  @apply cursor-pointer;
}

.bg-gradient-1 {
  background: linear-gradient(
    274deg,
    rgba(236, 82, 254, 1) 0%,
    rgba(30, 10, 245, 1) 100%
  );
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Common CSS Ends */
/* Form Elements CSS Start */
.label {
  @apply font-interMedium text-sm text-[#344054] inline-block;
}

.input-field {
  @apply rounded-lg py-2 px-3.5 text-[#101828] placeholder:text-[#667085];
}

.otp-input {
  width: "40px";
  height: "40px";
  font-size: "20px";
  margin: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  color: #1751d0;
}

.submit-btn-blue {
  @apply text-white font-interSemiBold tracking-wide rounded-lg bg-[#1751D0] py-2.5 px-2.5;
}

.cancle-btn-blue {
  @apply text-sm text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg px-3.5 py-2.5;
}

.custom-checkbox {
  display: block;
}

.custom-checkbox input {
  display: none;
}

.custom-checkbox label {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.custom-checkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #d0d5dd;
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 4px;
}

.custom-checkbox label:hover:before {
  border: 1px solid #1751d0;
}

.custom-checkbox input:checked + label:before {
  background-color: #1751d0;
  border-color: #1751d0;
}

.custom-checkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  /* top: 3px;
    left: 7px; */
  top: 3px;
  left: 6px;
  width: 6px;
  height: 11px;
  border: 2px solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-radio:checked,
.custom-radio:not(:checked) {
  display: none;
}

.custom-radio:checked + label,
.custom-radio:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  display: inline-block;
  line-height: 18px;
}

.custom-radio:checked + label::before,
.custom-radio:not(:checked) + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #d0d5dd;
  border-radius: 100%;
  background: #fff;
}

.custom-radio:not(:checked) + label:hover::before {
  border-color: #1751d0;
}

.custom-radio:checked + label::after,
.custom-radio:not(:checked) + label::after {
  content: "";
  width: 18px;
  height: 18px;
  border: 5px solid #1751d0;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.custom-radio:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.custom-radio:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* Form Elements CSS Ends */
/* Slider Styling Start */
.signup-slider-wrapper .slick-dots {
  position: static;
  bottom: 0px;
  /* padding-bottom: 40px; */
  display: flex;
  justify-content: center;
}

.signup-slider-wrapper ul.slick-custom-dots {
  padding-left: 75px;
  padding-right: 75px;
}

.signup-slider-wrapper ul.slick-custom-dots li span.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #4b3bf7;
  color: #4b3bf7;
  overflow: hidden;
  cursor: pointer;
}

.signup-slider-wrapper ul.slick-custom-dots li.slick-active span.dot {
  background-color: #1507ac;
  color: #1507ac;
  cursor: pointer;
}

.signup-slider-wrapper li.slick-arrow-left span {
  border: solid #1751d0;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(135deg);
  cursor: pointer;
}

.signup-slider-wrapper .slick-arrow-left,
.signup-slider-wrapper .slick-arrow-right {
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.signup-slider-wrapper .slick-arrow-left:hover,
.signup-slider-wrapper .slick-arrow-right:hover {
  background-color: #ebebeb;
  cursor: pointer;
}

.signup-slider-wrapper li.slick-arrow-right span {
  border: solid #4b3bf7;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(-45deg);
  cursor: pointer;
  position: relative;
  top: -1px;
  right: 1px;
}

.signup-slider-wrapper .slick-dots li {
  cursor: default;
}

.signup-slider-wrapper .slick-slider {
  height: calc(100vh - 1px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.review-space-slider.signup-slider-wrapper .slick-slider {
  height: unset;
}

.review-space-slider .slick-dots {
  padding-bottom: 10px;
  padding-top: 10px;
}

.review-space-slider.signup-slider-wrapper ul.slick-custom-dots {
  padding-left: 20px;
  padding-right: 20px;
}

.review-space-slider.signup-slider-wrapper
  ul.slick-custom-dots
  li.slick-active
  span.dot {
  background-color: #1751d0;
  color: #1751d0;
}

.review-space-slider .slick-slide {
  margin: 0px 8px;
}

/* .review-space-slider .slick-list {
	height: auto;
} */
/* Slider Styling Ends */
/* Chips Styling Start */
.chips-container {
  display: flex;
  flex-wrap: wrap;
}

.chip {
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 2px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.chip button {
  background: transparent;
  border: none;
  cursor: pointer;
  margin-left: 5px;
  color: #98a2b3;
  font-weight: bold;
}

textarea {
  padding: 0px 0px;
  height: 80px;
  overflow-y: visible;
}

/* Chips Styling Ends */
/* Modal Styling Start */
.modal {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  visibility: hidden;
  transition: all 0.5s ease-in;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: #000000cf;
}

.modal.is-visible {
  visibility: visible;
  opacity: 1;
}

.modal-wrapper {
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  background-color: #fff;
}

/* .bg-modal-shade {
	background-image: url('../images/bg-modal-shade.svg');
	background-repeat: no-repeat;
    background-size: contain;
    background-position: 0px 6px;
} */

@keyframes ds {
  0% {
    visibility: visible;
  }

  90% {
    opacity: 1;
  }

  100% {
    visibility: hidden;
    opacity: 0;
    display: none;
  }
}

/* Modal Styling Ends */
/* Planning Animation Start */
/* React Select Start */
.react-select-wrapper {
  margin-bottom: 8px;
}

.react-select-wrapper .react-select__option:last-of-type {
  margin-bottom: 0px;
}

.react-select-wrapper .react-select__input {
  font-family: "Inter", sans-serif !important;
  font-weight: 500 !important;
  color: #344054 !important;
  font-size: 15px !important;
}

.react-select-wrapper .react-select__indicator {
  padding: 0px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.react-select-wrapper .react-select__clear-indicator {
  display: none;
}

.react-select-wrapper .react-select__control {
  padding: 8px 5px;
}

.react-select-wrapper.multiselect .react-select__multi-value {
  margin: 2px 2px;
  border: 1px solid #d5d9eb;
  background-color: #f8f9fc;
  border-radius: 4px;
  padding: 0px 1px 1px 1px;
}

.react-select-wrapper.multiselect .react-select__multi-value__remove svg {
  fill: #85888e;
}

.react-select-wrapper.multiselect .react-select__multi-value__remove:hover {
  background-color: unset;
}

.react-select-wrapper.multiselect .react-select__multi-value__remove:hover svg {
  fill: red;
}

.react-select-wrapper.multiselect .react-select__multi-value__label {
  padding: 1px;
}

.react-select-wrapper.multiselect .react-select__control {
  padding: 2px 4px;
  background-color: #fff;
}

.modal-select-box
  .react-select-wrapper.multiselect
  .react-select__value-container {
  padding-right: 30px;
}

.disabled-multiselct .react-select-wrapper.multiselect {
  pointer-events: none;
}

.modal-select-box.disabled-multiselct
  .react-select-wrapper.multiselect
  .react-select__value-container,
.modal-select-box.disabled-multiselct .react-select-wrapper.multiselect {
  background-color: #f9fafb;
  pointer-events: none;
}

.modal-select-box .react-select__control {
  background-color: transparent;
}

.modal-select-box .react-select__value-container {
  background-color: #fff;
}

.modal-select-box .react-select__option {
  margin-bottom: 5px;
}

.multi-select-wrapper .select__control {
  position: relative;
  border: 1px solid #d0d5dd;
  display: flex;
  align-items: flex-start;
  min-height: 40.46px;
}

.multi-select-wrapper .select__control:hover {
  border-color: #1751d0;
}

.budget-dropdown-styling .select__control {
  padding: 4px 4px;
  border: none !important;
  width: 77px;
}

.budget-dropdown-styling .select__dropdown-indicator {
  padding: 0px;
}

/* .location-icon .select__control::before {
	content: "";
	width: 24px;
	height: 24px;
	background-image: url('../images/location-icon.svg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	margin-top: 4px 
} */

/* .timezone-icon .select__control::before {
	content: "";
	width: 20px;
	height: 20px;
	background-image: url('../images/timezone-icon.svg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	margin-top: 4px 
} */

/* .location-icon2::after {
	content: "";
	display: inline-block;
	width: 24px;
	height: 24px;
	background-image: url('../images/location-icon.svg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	margin-top: 4px 
} */

/* .timezone-icon2::before {
	content: "";
	width: 20px;
	height: 20px;
	background-image: url('../images/timezone-icon.svg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	margin-top: 4px 
} */

.multi-select-wrapper .select__input-container {
  margin: 0px;
}

.multi-select-wrapper .select__control:focus {
  border: 1px solid #d0d5dd;
}

.multi-select-wrapper .select__indicator {
  padding: 3px;
}

.multi-select-wrapper .select__multi-value {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 17px;
  font-weight: 500;
}

.multi-select-wrapper .select__multi-value__remove:hover {
  background-color: #fff;
}

.multi-select-wrapper .select__multi-value__remove svg {
  width: 14px;
  height: 14px;
  color: #98a2b3;
}

.multi-select-wrapper .select__multi-value__label {
  color: #344054;
  font-family: "Inter", sans-serif !important;
  font-weight: 500 !important;
  letter-spacing: 0.001rem;
  padding: 5px 1px 5px 6px;
  line-height: 1;
}

.multi-select-wrapper.location .select__indicator {
  display: none;
}

.multi-select-wrapper .select__multi-value__remove:hover svg {
  color: #525966;
}

.modal.is-visible .multi-select-wrapper .select__input-container {
  display: inline-grid;
}

.modal .multi-select-wrapper .select__input-container {
  display: none;
}

.adjust-height-modals .react-select__input-container {
  padding: 0px;
  margin: 0px;
}

.adjust-height-modals .react-select__input {
  padding-left: 6px !important;
}

/* React Select Ends */
/* Collapsed Sidebar start */
.collapsed-sidebar {
  width: 72px;
  transition: all 0.3s ease-in;
}

.collapsed-sidebar .collapsed-logo {
  pointer-events: none;
}

.collapsed-sidebar .collapsed-logo span {
  opacity: 0;
  width: 0;
  pointer-events: none;
  transition: width 0.2s, opacity 0.3s ease-in;
}

.collapsed-sidebar .react-select__indicators {
  display: none;
}

.react-select__indicators {
  transition-delay: 0.2s;
}

.collapsed-sidebar .react-select__control {
  padding: 8px 5px;
}

.collapsed-sidebar .collapsed-list a,
.collapsed-sidebar .collapsed-list span {
  opacity: 0;
  width: 0;
  pointer-events: none;
  transition: width 0.2s, opacity 0.3s ease-in;
}

.collapsed-sidebar .collapsed-list img {
  padding-left: 0px;
}

.collapsed-sidebar .collapsed-fate {
  opacity: 0;
  width: 0;
  pointer-events: none;
  transition: width 0.2s, opacity 0.3s ease-in;
}

.collapsed-sidebar .collapsed-icon img {
  transform: rotate(0deg);
  transition: all 0.2s ease-in;
}

.collapsed-sidebar .collapsed-dash {
  opacity: 0;
  width: 0;
  pointer-events: none;
  transition: width 0.2s, opacity 0.3s ease-in;
}

/* .sidebar-selectbox .react-select__option--is-selected {
	background-color: #E8E6FE;
} */

.react-select__option--is-selected {
  background-color: #1751d017 !important;
}

.react-select__option--is-selected:hover {
  color: #344054 !important;
}

.select__option--is-selected {
  background-color: #e8e6fe !important;
}

.select__option--is-selected:hover {
  color: #344054 !important;
}

.smoothness {
  transition: all 0.3s ease-in;
}

.switch-to-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #fff;
  height: 100%;
  z-index: 100;
}

.collapsed-sidebar .react-select-wrapper .react-select__option {
  padding: 2px 3px 2px 3px;
}

/* .collapsed-sidebar .react-select-wrapper .react-select__option,
.collapsed-sidebar .react-select-wrapper .react-select__option:hover {
	background-color: transparent;
} */
/* Collapsed Sidebar Ends */
/* Planning Animation Start*/
.planning-animation {
  padding-top: 24px;
  transition: all 0.3s;
  /* background-color: #f9fafb; */
  background: linear-gradient(
    180deg,
    rgba(249, 250, 251, 1) 0%,
    rgba(255, 255, 255, 1) 75%
  );
}

.planning-header {
  height: 42px;
  transition: height 0.4s;
}

.planning-animation .planning-header {
  opacity: 0;
  height: 0px;
  margin-bottom: 0px;
  pointer-events: none;
  transition: opacity 0.4s, height 0.4s;
}

.planning-fete-img {
  transition: width 0.35s;
}

.planning-animation .planning-fete-img {
  transition: width 0.4s;
}

.planning-animation .planning-fete-description {
  flex: none;
  transition: all 0.4s;
  padding-bottom: 140px;
  position: relative;
}

.planning-fete-info {
  margin-top: -282px;
  margin-left: 350px;
  transition: all 0.35s;
}

.planning-animation .planning-fete-info {
  margin-top: 16px;
  margin-left: 0px;
  transition: all 0.35s;
  min-height: auto;
  width: 334px;
}

.planning-fete-chatbox {
  transform: translateX(100%) scale(0);
  box-shadow: 0px 4px 8px -4px rgba(16, 24, 40, 0.03),
    0px 2px 24px -4px rgba(16, 24, 40, 0.08);
}

.planning-animation .planning-fete-chatbox {
  transform: translateX(0%) scale(1);
  transition: transform 0.4s;
  width: 100%;
}

.planning-animation .planning-anim-fete-description {
  /* height: calc(100vh - 114px); */
  transition: all 0.4s;
  margin-bottom: 16px;
}

.planning-animation .card-fete-chatbox {
  display: none;
}

.planning-animation .chat-box-height-toggle {
  /* height: calc(100vh - 323px); */
  height: calc(100vh - 265px);
}

.planning-animation .controls-buttons {
  display: none;
}

.planning-animation .controls-buttons-wrapper {
  display: block;
  margin-top: 0;
}

.planning-animation .planning-card-wrapper {
  display: none;
}

.planning-animation .only-fetebuddy {
  padding-left: 12px !important;
}

/* Planning Animation Ends*/
/* Cards Animation Start*/
.card-animation {
  padding-top: 24px;
  /* background-color: #f9fafb; */
  /* background: linear-gradient(180deg, rgba(249, 250, 251, 1) 0%, rgba(255, 255, 255, 1) 75%); */
  transition: all 0.3s;
}

.card-animation .planning-header {
  opacity: 0;
  height: 0px;
  margin-bottom: 0px;
  pointer-events: none;
  transition: opacity 0.4s, height 0.4s;
}

.card-animation .planning-fete-img {
  display: none;
}

.card-animation .planning-fete-info {
  margin-top: 0px;
  margin-left: 0px;
  border-radius: 12px;
  padding: 12px 20px;
  box-shadow: 0px 2px 2px 0px #d0d5dd;
  width: 360px;
  transition: none;
  background-color: #fff;
  min-height: auto;
}

.card-animation .card-anim-wrapper {
  display: none;
}

.card-animation .card-anim-organiser {
  padding-bottom: 0;
}

.card-animation .card-anim-wrapper {
  display: none;
}

.card-animation .card-anim-tertiary {
  border: none;
  display: block;
  padding: 0;
}

.card-animation .card-anim-block {
  display: none;
}

.card-animation .card-anim-block.selected {
  display: block;
  /* margin-bottom: 20px; */
}

.card-animation .card-anim-add-btn {
  display: none;
}

.card-animation .planning-card-wrapper {
  display: inline-block;
}

.card-animation .card-anim-fete-description {
  display: block;
  width: fit-content;
}

.card-animation .card-anim-structure-upper {
  display: flex;
  overflow: hidden;
  column-gap: 24px;
  align-items: flex-start;
  margin-bottom: 16px;
}

.card-fete-chatbox {
  transform: translate(100%, 100%);
  box-shadow: 0px 4px 8px -4px rgba(16, 24, 40, 0.03),
    0px 2px 24px -4px rgba(16, 24, 40, 0.08);
  background: #fff;
}

.card-animation .card-fete-chatbox {
  width: 100%;
  /* width: calc(100% - 420px); */
  /* border: 1px solid #EAECF0; */
  /* min-height: calc(100vh - 114px); */
  transform: translate(0%, 0%);
  transition: all 0.3s;
}

.card-animation .planning-fete-chatbox {
  display: none;
}

.card-animation .chat-box-height-toggle {
  height: calc(100vh - 232px);
}

.chat-box-height-toggle {
  height: 0px;
}

.card-animation .add-card-box {
  display: none;
}

.card-animation .controls-buttons {
  display: none;
}

.card-animation .card-anim-primary {
  border: none;
}

/* Cards Animation Ends*/
/* Custom Date Picker Start */
.custom-datepicker .react-datepicker-wrapper {
  width: 100%;
}

.custom-datepicker .react-datepicker__close-icon::after {
  background-color: #f2f4f7;
  padding: 0px 1px 1px 1px;
  color: #000000;
  font-size: 16px;
}

.custom-datepicker .react-datepicker__close-icon:hover::after {
  background-color: #e6e7e8;
}

.custom-datepicker .react-datepicker__close-icon {
  padding-right: 0px;
}

.custom-datepicker .react-datepicker {
  box-shadow: 0px 0px 8px 8px #e8e8e84a;
  font-family: "Inter", sans-serif !important;
  border: 1px solid #e0e0e0;
}

.custom-datepicker .react-datepicker__header {
  background-color: #fff;
  color: #1751d0;
  border-bottom: 1px solid #e2e2e2;
  padding: 14px 0;
}

.custom-datepicker .react-datepicker__navigation-icon::before {
  border-color: #344054;
  border-width: 2px 2px 0 0;
}

.custom-datepicker .react-datepicker__navigation-icon:hover::before,
.custom-datepicker
  .react-datepicker__navigation:hover
  .react-datepicker__navigation-icon::before {
  border-color: #1751d0;
}

.custom-datepicker .react-datepicker__navigation {
  top: 12px;
}

.custom-datepicker .react-datepicker__current-month,
.custom-datepicker .react-datepicker-time__header,
.custom-datepicker .react-datepicker-year-header {
  color: #344054;
  font-family: "Inter", sans-serif !important;
  font-weight: 500 !important;
  /* padding-bottom: 10px; */
  font-size: 18px;
}

.custom-datepicker .react-datepicker__day-name {
  color: #344054;
  font-family: "Inter", sans-serif !important;
  font-weight: 500 !important;
}

.custom-datepicker .react-datepicker__day--in-range,
.custom-datepicker
  .react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range
  ) {
  background-color: #1751d0 !important;
  color: #fff;
}

.custom-datepicker .react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  background-color: #1751d0 !important;
  color: #fff;
  font-weight: 500;
}

.custom-datepicker .react-datepicker__day {
  padding: 7px;
  width: 42px;
  border-radius: 50% !important;
  margin: 6px 3px;
}

.custom-datepicker.width-adjust .react-datepicker__day {
  /* padding: 5px 5px;
    width: 38px; */
  padding: 2px 5px;
  width: 32px;
  margin: 0px 2px;
}

.datepicker-width-adjust .custom-datepicker-input {
  width: 206px;
}

.modal-date-picker .react-datepicker,
.modal-date-picker .react-datepicker__month-container {
  width: 100%;
}

.modal-date-picker .react-datepicker {
  box-shadow: none;
}

.modal-date-picker .react-datepicker__day {
  margin: 6px 8px;
}

.due-modal .select__control {
  padding: 8px 12px;
}

.due-modal .select__menu-list {
  max-height: 210px;
}

/* Custom Date Picker Ends */

/* Pagination Styling Start */

.pagination {
  align-items: center;
}

.pagination li.pag-link {
  border: none !important;
  padding: 0px !important;
  height: 12px;
  border-radius: 50%;
  width: 12px;
  background-color: #ccc !important;
  color: transparent !important;
  margin: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination li.page-active {
  background-color: #1751d0 !important;
  color: transparent !important;
}

.pagination li.pag-link a {
  width: 12px;
  height: 12px;
  overflow: hidden;
}

.pagination .pag-link:last-child {
  margin-right: 0px;
}

.pagination li.pag-arrow {
  border: none;
  padding: 6px 0px 6px 0px;
  margin: 0px 18px;
}

.pag-arrow a {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
}

.previous-arrow a {
  transform: rotate(135deg);
}

.next-arrow a {
  transform: rotate(-45deg);
}

.disabled {
  pointer-events: none;
}

.disabled a {
  border-color: #c4c4c4;
  pointer-events: none;
}

/* Pagination Styling Ends */

/* Loader Styling Start */
.placeholder-loader {
  width: 360px;
  height: 100px;
  display: block;
  position: relative;
  background-image: linear-gradient(
      100deg,
      transparent,
      rgba(38, 50, 56, 0.112) 50%,
      transparent 80%
    ),
    linear-gradient(#f0f0f0 20px, transparent 0),
    linear-gradient(#f0f0f0 20px, transparent 0),
    linear-gradient(#f0f0f0 20px, transparent 0);
  background-repeat: no-repeat;
  background-size: 75px 100px, 125px 20px, 260px 20px, 260px 20px;
  background-position: 0% 0, 120px 0, 120px 40px, 120px 80px;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}

.placeholder-loader::after {
  content: "";
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  background: #f0f0f0;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes animloader {
  0% {
    background-position: 0% 0, 120px 0, 120px 40px, 120px 80px;
  }

  100% {
    background-position: 100% 0, 120px 0, 120px 40px, 120px 80px;
  }
}

/* Loader Styling Ends */

/* Toastify Styling Start */
.Toastify__close-button {
  margin: 6px 4px 0px 0px;
  opacity: 1 !important;
}

.Toastify__close-button > svg {
  height: 20px !important;
  width: 20px !important;
  fill: #d5d5d5 !important;
}

.Toastify__toast-container {
  width: 100% !important;
  max-width: 400px;
}

.Toastify__toast {
  border: 1px solid #d0d5dd;
  border-radius: 12px !important;
}

.Toastify__progress-bar--wrp {
  height: 10px !important;
}

.Toastify__progress-bar--success {
  background: #079455 !important;
}

.Toastify__progress-bar--warning {
  background: #ffc107 !important;
}

.Toastify__progress-bar--info {
  background: #1751d0 !important;
}

.Toastify__progress-bar--error {
  background: #d92d20 !important;
}

.Toastify__toast-icon {
  display: none !important;
}

/* Toastify Styling Ends */
/* Charts Styling Start */
.apexcharts-text {
  font-family: "Inter", sans-serif !important;
}

.apexcharts-datalabel-label {
  font-size: 16px;
  font-weight: 500;
  fill: #475467;
}

.apexcharts-datalabel-value {
  font-size: 24px;
  fill: #101828;
  font-weight: 600;
}

.apexcharts-xaxis-label tspan {
  fill: #667085;
}

.apexcharts-bar-area {
  clip-path: inset(0% 0% -4px 0% round 4px);
}

.response-chart .apexcharts-legend {
  flex-direction: column;
}

.response-chart .apexcharts-legend-text {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #344054 !important;
  padding-left: 18px;
  width: fit-content;
}

/* Charts Styling Ends */
/* Insights Styling Starts */
.insights .new-chat-btn,
.insights .past-chat,
.insights .fetebuddy-footer,
.insights .fetebuddy-screen {
  display: none;
}

.insights .insight-body {
  padding: 0;
}

.insight-body.chat-box-height-toggle {
  height: calc(100vh - 267px);
}

.review-space-body.chat-box-height-toggle {
  height: calc(100vh - 152px);
}

.review-space-body {
  border-bottom-left-radius: 12px;
}

.review-space .fetebuddy-footer-wrapper {
  display: none;
}

.insights .apexcharts-canvas {
  margin: 0 auto;
}

.insights .insight-chart-wrapper {
  width: 100%;
}

.insight-wrapper {
  display: none;
}

.insights .insight-wrapper {
  display: flex;
}

.insight-footer {
  display: none;
}

.insights .insight-footer {
  display: flex;
}

/* Insights Styling Ends */

/* Editor Styling Start */
.no-css * {
  all: revert;
}

.no-css table {
  border-collapse: collapse;
}

.no-css table:not([cellpadding]) td,
table:not([cellpadding]) th {
  padding: 4px;
}

.no-css table[border]:not([border="0"]):not([style*="border-width"]) td,
table[border]:not([border="0"]):not([style*="border-width"]) th {
  border-width: 1px;
}

.no-css table[border]:not([border="0"]):not([style*="border-style"]) td,
table[border]:not([border="0"]):not([style*="border-style"]) th {
  border-style: solid;
}

.no-css table[border]:not([border="0"]):not([style*="border-color"]) td,
table[border]:not([border="0"]):not([style*="border-color"]) th {
  border-color: #ccc;
  padding: 3px 6px;
}

.no-css img {
  width: revert-layer;
  height: revert-layer;
}

.tox-statusbar__branding,
.tox-statusbar__path-item {
  display: none !important;
}

.tox-statusbar__help-text {
  opacity: 0;
}

/* Editor Styling Ends */

/* Other Styling Start */
/* .bg-rounded-pattern {
	background-image: url('../images/bg-pattern-rounded.svg');
	background-position: top center;
    background-size: auto;
    background-repeat: no-repeat;
} */

/* .bg-rounded-pattern-lg {
	background-image: url('../images/circular-pattern-lg.svg');
	background-position: top center;
    background-size: auto;
    background-repeat: no-repeat;
} */

.password-checkbox .custom-checkbox label:before {
  margin-right: 8px;
}

.password-checkbox .custom-checkbox label {
  font-size: 14px;
  color: #344054;
  line-height: 1;
}

.dash-styling {
  width: 0px;
  height: 0px;
}

.dash-styling:nth-of-type(3) {
  width: 14px;
  height: 5px;
  background-color: #d0d5dd;
  margin: 0 5px;
}

.otp-forgot-password input {
  width: 46px !important;
}

.otp-forgot-password .dash-styling:nth-of-type(3) {
  width: 14px;
  height: 5px;
  background-color: #d0d5dd;
  margin: 0 5px;
}

.custom-padding-right input {
  padding-right: 40px;
}

.svg-blue {
  display: none;
}

.svg-grey {
  display: block;
}

.team-row:hover .svg-blue {
  display: block;
}

.team-row:hover .svg-grey {
  display: none;
}

.custom-table-grid {
  display: grid;
  grid-template-columns: 25% 25% 30% 20%;
  position: relative;
}

.custom-table-grid-pending-list {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  position: relative;
}

.custom-table-grid-attendees-list {
  display: grid;
  grid-template-columns: 15% 15% 20% 15% 15% 10% 10%;
  position: relative;
}

.custom-table-grid-attendees-list-edit {
  display: grid;
  grid-auto-flow: column;
  position: relative;
}

.custom-table-grid-attendees-list-view {
  display: grid;
  grid-template-columns: 9% 9% 9% 9% 9% 9% 9% 9% 9% 9% 10%;
  position: relative;
}

.custom-table-grid-attendees-file-list {
  display: grid;
  grid-template-columns: 20% 80%;
  position: relative;
}

.custom-table-grid-attendee-upload {
  display: grid;
  grid-auto-flow: column;
  position: relative;
  border-bottom: 0px solid #eaecf0;
}

.custom-table-grid-attendee-upload:last-of-type {
  border-bottom: 1px solid #eaecf0;
}

.custom-table-grid-list {
  display: grid;
  grid-template-columns: 333px 240px 160px 136px 136px 240px 240px 240px 160px 280px 85px;
  position: relative;
  /* overflow-x: scroll; */
}

.card-heading .custom-checkbox-label {
  padding-left: 8px;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.card-children .custom-checkbox-label {
  display: none;
}

.rotate-right {
  transform: rotate(5deg);
  transform-origin: right top;
}

.collapsed-list:hover .on-hover-blue {
  filter: brightness(0) saturate(100%) invert(16%) sepia(93%) saturate(2149%)
    hue-rotate(213deg) brightness(92%) contrast(95%);
}

.filter-blue {
  filter: invert(12%) sepia(100%) saturate(5195%) hue-rotate(237deg)
    brightness(90%) contrast(115%);
}

.filter-blue2 {
  filter: brightness(0) saturate(100%) invert(21%) sepia(95%) saturate(2338%)
    hue-rotate(230deg) brightness(89%) contrast(127%);
}

.logigruphover:hover img {
  filter: brightness(0) saturate(100%) invert(21%) sepia(95%) saturate(2338%)
    hue-rotate(230deg) brightness(89%) contrast(127%);
}

.logigruphover:hover p {
  color: #1751d0;
}

.filter-pink {
  filter: invert(73%) sepia(58%) saturate(7486%) hue-rotate(259deg)
    brightness(99%) contrast(180%);
}

.on-hover-red:hover {
  filter: invert(18%) sepia(99%) saturate(4810%) hue-rotate(358deg)
    brightness(88%) contrast(132%);
}

.on-hover-blue:hover {
  filter: brightness(0) saturate(100%) invert(16%) sepia(93%) saturate(2149%)
    hue-rotate(213deg) brightness(92%) contrast(95%);
}

.on-hover-blue-child-img:hover img {
  filter: brightness(0) saturate(100%) invert(16%) sepia(93%) saturate(2149%)
    hue-rotate(213deg) brightness(92%) contrast(95%);
}

.on-hover-red:hover {
  filter: brightness(0) saturate(100%) invert(14%) sepia(100%) saturate(6756%)
    hue-rotate(359deg) brightness(99%) contrast(111%);
}

.on-hover-border-blue {
  border: 1px solid transparent;
}

.on-hover-border-blue:hover {
  border: 1px solid #1751d0;
}

.border-blue {
  border: 1px solid #1751d0;
}

.on-hover-border-blue-2 {
  border: 2px solid transparent;
}

.on-hover-border-blue-2:hover {
  border: 2px solid #1751d0;
}

.on-hover-border-shadow {
  border: 2px solid transparent;
}

.on-hover-border-shadow:hover {
  border: 2px solid #fff;
  box-shadow: 0px 8px 18px 2px #dbdbdb;
}

.border-blue-2 {
  border: 2px solid #1751d0;
}

.blue-btn-hover:hover {
  background-color: #113b98;
}

.on-hover-bg-blue:hover {
  background-color: #e8e6fe;
}

.on-hover-bg-red:hover {
  background-color: #fef3f2;
}

.on-hover-bg-green:hover {
  background-color: #bbe1bb;
}

.on-focused-bg-blue {
  background-color: #e8e6fe;
}

.on-hover-bg-grey:hover {
  background-color: #f2f4f7;
}

.on-hover-blue-float-bar:hover img,
.on-hover-blue-float-bar:hover span {
  filter: invert(9%) sepia(100%) saturate(7388%) hue-rotate(249deg)
    brightness(93%) contrast(109%);
}

.on-blue-float-bar img,
.on-blue-float-bar span {
  filter: invert(9%) sepia(100%) saturate(7388%) hue-rotate(249deg)
    brightness(93%) contrast(109%);
}

.button-disabled {
  @apply text-[#98A2B3] font-interMedium bg-[#F2F4F7] border border-[#E4E7EC] pointer-events-none;
}

.button-disabled2 {
  @apply text-[#98A2B3] font-interMedium bg-[#F2F4F7] border border-[#E4E7EC] pointer-events-none;
}

.button-disabled2 img {
}

.dropdown-disabled {
  @apply text-[#98A2B3] font-interSemiBold bg-[#f2f4f7] border-[#e4e7ec] pointer-events-none rounded-lg opacity-50;
}

.list-row-disabled .disabled-row-box {
  @apply text-[#98A2B3] font-interSemiBold bg-[#F2F4F7] pointer-events-none hover:bg-[#F2F4F7];
}

.dropdown-disabled .react-select-wrapper {
  margin-bottom: 0;
}

.dropdown-disabled .react-select__value-container {
  @apply bg-[#F2F4F7];
}

.calender-disabled {
  @apply bg-[#F2F4F7] border border-[#E4E7EC] pointer-events-none;
}

.calender-disabled .custom-datepicker-input {
  @apply !bg-[#F2F4F7];
}

.modal-select-box.dropdown-disabled .react-select__value-container {
  background-color: #f2f4f7;
}

.sidebar-menu.active {
  background-color: #f5f8fd;
}

.sidebar-menu.active .on-hover-blue {
  filter: brightness(0) saturate(100%) invert(24%) sepia(97%) saturate(1915%)
    hue-rotate(210deg) brightness(90%) contrast(93%);
}

.sidebar-menu.active .sidebar-link {
  filter: brightness(0) saturate(100%) invert(24%) sepia(97%) saturate(1915%)
    hue-rotate(210deg) brightness(90%) contrast(93%);
}

.sidebar-menu.active .sidebar-menu-blue-line {
  display: block;
}

.svg-icon-blue {
  filter: brightness(0) saturate(100%) invert(24%) sepia(97%) saturate(1915%)
    hue-rotate(210deg) brightness(90%) contrast(93%);
}

.custom-group-hover:hover .group-hover-child {
  filter: brightness(0) saturate(100%) invert(16%) sepia(93%) saturate(2149%)
    hue-rotate(213deg) brightness(92%) contrast(95%);
}

.custom-group-hover:hover .group-hover-child-text {
  color: #1751d0;
}

/* .rounded-corner li{
	background-image: url(../images/tab-shape-1.svg);
	background-repeat: no-repeat;
    background-position: center;
    width: 155px;
    height: 52px;
    display: flex;
    align-items: center;
    padding-left: 35px;
    position: relative;
} */

.rounded-corner li:nth-of-type(1) {
  z-index: 3;
}

/* .rounded-corner li:nth-of-type(2){
	z-index: 2;
	margin-left: -48px;
	background-image: url(../images/tab-shape-2.svg);
	width: 186px;
	padding-left: 48px;
} */

/* .rounded-corner li:nth-of-type(3){
	z-index: 1;
	margin-left: -48px;
	background-image: url(../images/tab-shape-2.svg);
	width: 186px;
	padding-left: 48px;
} */

.remove-border-bottom {
  position: relative;
}

.remove-border-bottom::after {
  content: "";
  display: block;
  background-color: #f9fafb;
  width: 90.5%;
  height: 2px;
  position: absolute;
  left: 11px;
  bottom: 0px;
}

.remove-border-bottom:nth-of-type(1)::after {
  content: "";
  display: block;
  background-color: #f9fafb;
  width: 95%;
  height: 2px;
  position: absolute;
  left: 0px;
  bottom: 0px;
}

/* .rounded-corner-chatbox li{
	background-image: url(../images/tab-shape-4.svg);
} */

/* .rounded-corner-chatbox li:nth-of-type(2){
	background-image: url(../images/tab-shape-5.svg);
} */

.rounded-corner-chatbox .remove-border-bottom::after {
  background-color: #fff;
  width: 94%;
  left: 2px;
}

.rounded-corner-chatbox .remove-border-bottom:nth-child(2)::after {
  background-color: #fff;
  width: 90%;
  left: 11px;
}

/* .star-color-icon {
	background-image: url(../images/star-color-icon.svg);
} */

/* .star-color-icon:hover {
	background-image: url(../images/star-color-icon-filled.svg);
} */

.tab-rounded div:nth-of-type(1) {
  border-radius: 8px 0px 0px 8px;
}

.tab-rounded div:nth-of-type(3) {
  border-radius: 0px 8px 8px 0px;
}

.rc-slider {
  z-index: 0 !important;
}

.rc-slider-rail {
  height: 7px !important;
  background-color: #eaecf0 !important;
}

.rc-slider-track,
.rc-slider-tracks {
  height: 7px !important;
  background-color: #4b3bf7 !important;
}

.rc-slider-handle {
  width: 20px !important;
  height: 20px !important;
  opacity: 1 !important;
  border: 2px solid #4b3bf7 !important;
  margin-top: -6px !important;
}

.rc-slider-handle:hover {
  border-color: #4b3bf7 !important;
}

.rc-slider-handle:active {
  border-color: #4b3bf7 !important;
  box-shadow: none !important;
}

.fete-settings-modals .react-select__control {
  border: 1px solid #ccc;
}

.fete-settings-modals .react-select__control:hover {
  border: 1px solid #1751d0;
}

.logistics-modal .react-select__control {
  background-color: #fff;
}

.logistics-modal .react-select-wrapper {
  margin-bottom: 0;
}

.react-select__control--is-focused {
  border: 1px solid #1751d0 !important;
}

.disable-hover-on-focus .react-select__control--is-focused {
  border: 0 !important;
}

.select__control--is-focused {
  border: 1px solid #1751d0 !important;
}

/* .fete-title-icon {
	background-image: url('../images/fete-icon.svg');
	background-repeat: no-repeat;
    background-size: inherit;
    background-position: 12px center;
} */

/* .fete-type-icon {
	background-image: url('../images/fete-type-icon.svg');
	background-repeat: no-repeat;
    background-size: inherit;
    background-position: 12px center;
} */

/* .box-title-icon {
	background-image: url('../images/text-box-icon.svg');
	background-repeat: no-repeat;
    background-size: inherit;
    background-position: 12px center;
} */

/* .photo-title-icon {
	background-image: url('../images/image-icon.svg');
	background-repeat: no-repeat;
    background-size: inherit;
    background-position: 12px center;
	background-size: 22px;
} */

/* .x-title-icon {
	background-image: url('../images/x-icon.svg');
    background-size: inherit;
    background-position: 12px center;
	background-size: 22px;
	background-repeat: no-repeat;
} */

/* .facebook-title-icon {
	background-image: url('../images/facebook-icon.svg');
	background-repeat: no-repeat;
    background-size: inherit;
    background-position: 12px center;
	background-size: 22px;
} */

/* .instagram-title-icon {
	background-image: url('../images/instagram-icon.svg');
	background-repeat: no-repeat;
    background-size: inherit;
    background-position: 12px center;
	background-size: 22px;
} */

/* .linkedin-title-icon {
	background-image: url('../images/linkedin-icon.svg');
	background-repeat: no-repeat;
    background-size: inherit;
    background-position: 12px center;
	background-size: 22px;
} */

/* .website-title-icon {
	background-image: url('../images/website-icon.svg');
	background-repeat: no-repeat;
    background-size: inherit;
    background-position: 12px center;
	background-size: 22px;
} */

.checkbox-alignment .custom-checkbox input:checked + label:after {
  top: 2px;
}

.checkbox-alignment2 .custom-checkbox input:checked + label:after {
  top: 3px;
}

.arrow-top {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #000;
}

.arrow-bottom {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #000;
}

.arrow-bottom2 {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #000;
}

.drag-img {
  display: none;
}

.card-animation .card-selected.active .card-heading {
  background-color: #fde5ff;
  padding: 8px 8px;
  /* margin: -10px -10px -8px -10px; */
}

.card-animation .card-selected.active .drag-img {
  display: block;
}

/* .card-animation .card-selected.active .card-heading .star-color-icon {
	background-image: url(../images/star-color-icon-filled.svg);
} */

.home-dropdown .react-select-wrapper {
  margin-bottom: 0;
}

.home-dropdown .react-select__indicators {
  display: none;
}

.home-dropdown .react-select-wrapper .react-select__control,
.home-dropdown .react-select__value-container {
  background-color: white;
}

.home-dropdown .react-select-wrapper .react-select__control {
  padding: 8px 12px;
}

.home-dropdown .dropdown-value {
  margin-left: 0px;
}

.home-dropdown .react-select-wrapper {
  width: 110px;
}

.rfp-dropdwon .react-select-wrapper {
  width: 130px;
}

.home-dropdown .react-select__option {
  margin-bottom: 4px;
}

.home-dropdown .react-select__menu {
  margin-top: 1px;
}

.transparent-scrollbar::-webkit-scrollbar-track {
  @apply bg-[#fff];
}

.hide-scrollbar::-webkit-scrollbar-track {
  @apply bg-[#F9FAFB];
}

.hide-scrollbar::-webkit-scrollbar {
  @apply w-1.5 h-1.5;
}

.transparent-scrollbar2::-webkit-scrollbar-track {
  @apply bg-[#F9FAFB];
}

.hide-scrollbar3::-webkit-scrollbar-track {
  @apply bg-white;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
  /* @apply rounded-md bg-[#ededed]; */
  @apply rounded-md bg-[#dedede];
}

.hide-scrollbar2::-webkit-scrollbar {
  display: none;
}

.on-hover-show-scrollbar::-webkit-scrollbar-track {
  @apply bg-transparent;
}

.on-hover-show-scrollbar::-webkit-scrollbar {
  @apply w-1.5 h-1.5;
}

.on-hover-show-scrollbar::-webkit-scrollbar-thumb {
  @apply rounded-md bg-transparent;
}

.on-hover-show-scrollbar:hover::-webkit-scrollbar-track {
  @apply bg-[#ededed];
}

.on-hover-show-scrollbar:hover::-webkit-scrollbar {
  @apply w-1.5 h-1.5;
}

.on-hover-show-scrollbar:hover::-webkit-scrollbar-thumb {
  @apply rounded-md bg-[#d2d2d2];
}

.review-space .rounded-shape-bg-chatbox {
  display: none;
}

.fete-buddy .review-space-screen {
  display: none;
}

.review-space .fetebuddy-screen {
  display: none;
}

.review-space .new-chat-btn {
  display: none;
}

.review-space .past-chat {
  display: none;
}

.review-space .fetebuddy-footer {
  display: none;
}

.fete-buddy .review-space-footer {
  display: none;
}

.review-space .chat-box-height-toggle {
  padding: 0px 0px;
}

.chatbox-dropdowns .select__value-container {
  padding: 2px 0px 0px 0px;
}

.decativated-card {
  background-color: #f2f4f7 !important;
  user-select: none !important;
  pointer-events: none !important;
}

.decativated-card img {
  filter: grayscale(1);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination li {
  display: inline-block;
  margin-right: 5px;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.pagination li.page-active {
  background-color: #007bff;
  color: #fff;
}

.pointer-none {
  pointer-events: none;
}

.card-checkbox-adjust .custom-checkbox label:before {
  display: none;
}

.card-checkbox-adjust .custom-checkbox span {
  padding-left: 0px;
}

.card-child-checkbox .custom-checkbox label {
  align-items: flex-start;
}

.card-child-checkbox .custom-checkbox label:before {
  margin-top: 3px;
}

.scrollbar-adjust::-webkit-scrollbar-track {
  background-color: #fff;
}

.sidebar-selectbox .react-select__value-container {
  min-height: 32px;
}

.collapsed-block .card-children-wrapper {
  opacity: 0;
  overflow: hidden;
  height: 0px;
}

.collapsed-block .card-heading {
  margin-bottom: 0;
}

.collapsed-block .toggle-icon {
  transform: rotate(0deg);
}

.accordion-content {
  opacity: 1;
  overflow: visible;
  height: auto;
  transition: all 0.6s ease-in-out;
}

.accordion-hide .accordion-content {
  opacity: 0;
  overflow: hidden;
  height: 0px;
  transition: all 0.6s ease-in-out;
}

.accordion-hide .accordion-heading {
  margin-bottom: 0;
  transition: all 0.6s ease-in-out;
  border-radius: 8px 8px 8px 8px;
}

.active-accordion .accordion-heading {
  background-color: #f0f6ff;
  border-color: #cce0ff;
  border-radius: 8px 8px 0px 0px;
}

.accordion-arrow {
  transform: rotateX(0deg);
  transition: all 0.4s ease-in-out;
}

.accordion-hide .accordion-arrow {
  transform: rotateX(-180deg);
  transition: all 0.4s ease-in-out;
}

.rotate-ver {
  transform: rotateX(180deg);
}

.sidebar-selectbox.sidebar-dropdown-adjust .react-select__menu-list {
  padding: 14px 15px;
  max-height: 500px;
}

.sidebar-selectbox.sidebar-dropdown-adjust
  .react-select__menu-list::-webkit-scrollbar-track {
  @apply bg-[#f9fafb];
}

.sidebar-selectbox.sidebar-dropdown-adjust
  .react-select__menu-list::-webkit-scrollbar-thumb {
  @apply bg-[#dedede];
}

.sidebar-selectbox.sidebar-dropdown-adjust .react-select__option {
  margin-bottom: 12px;
  padding: 8px 12px;
}

.sidebar-selectbox.sidebar-dropdown-adjust .react-select__option:last-of-type {
  margin-bottom: 0px;
}

.sidebar-selectbox.sidebar-dropdown-adjust
  .react-select__option:nth-last-of-type(2) {
  margin-bottom: 0px;
}

.sidebar-selectbox.sidebar-dropdown-adjust .react-select__menu {
  border-radius: 12px;
  width: 350px;
  box-shadow: 0px 0px 18px 5px #e8e8e8eb;
}

.sidebar-selectbox.sidebar-dropdown-adjust .react-select__control {
  border-radius: 8px 8px 8px 8px;
}

.sidebar-selectbox.sidebar-dropdown-adjust
  .react-select__option:nth-last-of-type(1):hover,
.sidebar-selectbox.sidebar-dropdown-adjust
  .react-select__option:nth-last-of-type(1) {
  background-color: unset;
  cursor: default;
}

.sidebar-selectbox.sidebar-dropdown-adjust
  .react-select__option:nth-last-of-type(2) {
  background-color: #fff;
  border: 1px solid #b4b4b4;
}

.sidebar-selectbox.sidebar-dropdown-adjust
  .react-select__option:nth-last-of-type(2):hover {
  background-color: #2d1af6;
  color: #fff;
}

/* .collapsed-sidebar .sidebar-selectbox.sidebar-dropdown-adjust .react-select__option:nth-last-of-type(2):hover{
	background-color: unset;
	color: unset;
} */

/* select.react-time-picker__inputGroup__input {
	-webkit-appearance: none;
	-moz-appearance: none;
	background: transparent;
	background-image: url("../images/down-arrow-icon2.svg");
	background-repeat: no-repeat;
    background-position-x: 32px;
    background-position-y: 8px;
    padding: 0px 20px 0px 0px;
    background-size: 11px;
  } */

/* .down-arrow {
	background-image: url("../images/down-arrow-icon2.svg");
	background-repeat: no-repeat;
    background-position-x: calc(100% - 14px);
    background-position-y: 19px;
    background-size: 14px;
}  */

.pointer-cursor {
  cursor: pointer;
}

.bg-shade-recent-activity::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #eaecf0;
  width: 100%;
  height: 100%;
  transform: rotate(-1.88deg) translate(-3px, 3px);
  z-index: 0;
  border-radius: 12px;
}

.btn-disabled {
  opacity: 0.5;
}

.planning-board-border-bottom::after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: #f154ff;
  position: absolute;
  bottom: -13px;
  left: 50%;
  transform: translateX(-50%);
}

.planning-board-hovered:hover::after {
  content: "";
  height: 2px;
  width: 140%;
  background-color: #f154ff;
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
}

.custom-rfp-table-grid {
  display: grid;
  grid-template-columns: 50% 25% 25%;
  position: relative;
}

.custom-rfp-tabular-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  position: relative;
}

.customise-task-table-grid {
  display: grid;
  grid-template-columns: 30% 30% 20% 10%;
}

.bg-grad-grey {
  /* background: linear-gradient(180deg, rgba(249, 250, 251, 1) 0%, rgba(255, 255, 255, 1) 75%); */
  background-image: radial-gradient(
    circle at 1px 1px,
    #cccccc 1px,
    transparent 0
  );
  background-size: 24px 24px;
}

.rfp-custom-checkbox {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.tox .tox-edit-area::before {
  border: none !important;
}

.send-mail .react-select__control {
  background-color: #fff;
}

.send-mail .react-select__value-container {
  background-color: #fff;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  /* Optional: semi-transparent background */
  z-index: 9999;
  /* Ensure loader is on top */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.content {
  /* Styles for your actual content */
}

.error {
  color: red;
}

/* Add this CSS to your stylesheet */

.image-loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #4f46e5;
  /* Indigo color */
  border-radius: 50%;
  width: 24px;
  /* Adjust size as needed */
  height: 24px;
  /* Adjust size as needed */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.line {
  margin-bottom: 5px;
}

.adjust-z-layer .react-datepicker-popper {
  z-index: 40;
}

.disabled-chat-div {
  pointer-events: none;
  opacity: 0.5;
}

.tox .tox-dialog-wrap__backdrop {
  background-color: #000000bf !important;
}

.hover-fete-effect:hover img {
  transition: all 0.3s ease-in;
  transform: rotate(0deg);
}

.hover-fete-effect img {
  transform: rotate(360deg);
  transition: all 0.3s ease-in;
}

/* Other Styling Ends */

/* Vendor Styling */
.circle-shape::after {
  content: "";
  position: absolute;
  top: -2px;
  left: -40px;
  background-color: #f9fafb;
  border-radius: 9999px;
  width: 24px;
  height: 24px;
  z-index: 0;
  box-shadow: 0px 0px 0px 5px transparent;
}

.circle-shape::before {
  content: "";
  position: absolute;
  top: 5px;
  left: -33px;
  z-index: 1;
  background-color: #d0d5dd;
  border-radius: 9999px;
  width: 10px;
  height: 10px;
}

.line-shape::after {
  content: "";
  position: absolute;
  top: 28px;
  left: -30px;
  z-index: 1;
  background-color: #d2cefd;
  border-radius: 9999px;
  width: 4px;
  height: calc(100% - 36px);
}

.line-shape:last-of-type::after {
  display: none;
}

.active .circle-shape::after {
  content: "";
  position: absolute;
  top: -2px;
  left: -40px;
  background-color: #f9fafb;
  border-radius: 9999px;
  width: 24px;
  height: 24px;
  z-index: 0;
  box-shadow: 0px 0px 0px 5px #3d24f3;
}

.active .circle-shape::before {
  content: "";
  position: absolute;
  top: 5px;
  left: -33px;
  z-index: 1;
  background-color: #1751d0;
  border-radius: 9999px;
  width: 10px;
  height: 10px;
}

.done .circle-shape::before {
  content: "\2713";
  display: inline-block;
  position: absolute;
  top: -2px;
  left: -36px;
  z-index: 1;
  color: #1751d0;
  font-size: 18px;
  background-color: transparent;
  width: unset;
  height: unset;
  border-radius: unset;
}

/* .x-title-icon-v {
	background-image: url('../images/vendor/vtwitter.svg');
    background-size: inherit;
    background-position: 12px center;
	background-size: 22px;
	background-repeat: no-repeat;
} */

/* .facebook-title-icon-v {
	background-image: url('../images/vendor/vfacebook.svg');
	background-repeat: no-repeat;
    background-size: inherit;
    background-position: 12px center;
	background-size: 22px;
} */

/* .instagram-title-icon-v {
	background-image: url('../images/vendor/vinstagram.svg');
	background-repeat: no-repeat;
    background-size: inherit;
    background-position: 12px center;
	background-size: 22px;
} */

/* .linkedin-title-icon-v {
	background-image: url('../images/vendor/vlinkedin.svg');
	background-repeat: no-repeat;
    background-size: inherit;
    background-position: 12px center;
	background-size: 22px;
} */

/* .youtube-title-icon-v {
	background-image: url('../images/vendor/vyoutube.svg');
	background-repeat: no-repeat;
    background-size: inherit;
    background-position: 12px center;
	background-size: 22px;
} */

/* .pinterest-title-icon-v {
	background-image: url('../images/vendor/vpinterest.svg');
	background-repeat: no-repeat;
    background-size: inherit;
    background-position: 12px center;
	background-size: 22px;
} */

.vendor-slider .slick-arrow-left,
.vendor-slider .slick-arrow-right {
  display: none;
}

.vendor-slider .slick-dots {
  position: static;
  background-color: #fff;
  margin-top: 12px;
}

.vendor-slider .dot {
  background-color: #e4e7ec;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  color: #e4e7ec;
  overflow: hidden;
  display: inline-block;
}

.vendor-slider .slick-active .dot {
  background-color: #1751d0;
  color: #1751d0;
}

.list-row-hover:hover .list-row-hover-box {
  background-color: #f9fafb;
}

.margin-adjust .react-select-wrapper {
  margin-bottom: 0;
}

.multicolor-select__option:hover,
.multicolor-select__option:active {
  background-color: unset !important;
}

.multicolor-select__option {
  background-color: #fff !important;
  display: flex !important;
  justify-content: center;
}

.multicolor-select__menu-list {
  border: 1px solid #1751d0 !important;
  border-radius: 12px !important;
  min-height: 220px;
}

.multicolor-select__menu {
  border-radius: 12px !important;
  border: unset !important;
  box-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a !important;
  z-index: 100 !important;
}

.multicolor-select__control,
.multicolor-select__control:hover {
  border: unset !important;
  background-color: unset !important;
  box-shadow: unset !important;
}

.multicolor-select__indicators {
  display: none !important;
}

.multicolor-select__single-value,
.multicolor-select__input-container {
  width: 127px !important;
  cursor: pointer;
}

.multicolor-select__value-container {
  justify-content: center;
}

.multicolor-select__input {
  caret-color: transparent;
}

.v2-select__option {
  padding: 0 !important;
  background-color: #fff !important;
}

.v2-select__option:hover,
.v2-select__option:active {
  background-color: unset !important;
}

.v2-select__menu {
  width: 175px !important;
}

.v2-select__menu-list {
  max-height: 340px !important;
}

/* Timeline styling */
.hdimjX {
  padding-bottom: 4px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #b0c;
}

.hdimjX:first-child::after {
  display: none;
}

.sc-khjJjR div:hover {
  background-color: unset !important;
}

.fLoswr,
.btlFdD {
  background-color: #fff !important;
}

.fLoswr:hover {
  border-color: #1751d0;
}

.fLoswr svg:hover {
  cursor: pointer;
}

.fQaywZ:hover,
.cTDyHL:hover {
  color: #1751d0;
  cursor: pointer;
}

.fQaywZ:hover svg {
  filter: invert(9%) sepia(100%) saturate(7388%) hue-rotate(249deg)
    brightness(93%) contrast(109%);
}

.jqLATO:hover {
  box-shadow: 0px 8px 18px 2px #dbdbdb;
  cursor: pointer;
}

.etAxvn {
  color: #f154ff !important;
  border-radius: 8px !important;
}

.bFYVFX {
  font-size: 14px !important;
  display: inline-block !important;
  padding-bottom: 6px !important;
  color: #344054 !important;
  font-weight: 600 !important;
}

.sc-iKOlBE {
  display: none !important;
}

.curijJ {
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #667085 !important;
}

.sc-cCzKKE {
  border: 1px solid #f266ff !important;
}

.gRBQJe {
  margin: 4px 16px !important;
}

.jlxfhV {
  color: #f154ff !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

/* For dev UI */
.kmMNGY {
  font-size: 14px !important;
  display: inline-block !important;
  padding-bottom: 6px !important;
  color: #344054 !important;
  font-weight: 600 !important;
}

.kTygre {
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #667085 !important;
}

.inYvPV {
  padding-bottom: 4px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #b0c;
}

.inYvPV:first-child::after {
  display: none;
}

.dQDFVd {
  color: #f154ff !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.camjoi {
  margin: 4px 16px !important;
}

.sc-cCzKKE {
  border: 1px solid #f266ff !important;
}

.kMjfEb {
  color: #f154ff !important;
  border-radius: 8px !important;
}

/* New Design */
.bg-dots {
  background-image: radial-gradient(
    circle at 1px 1px,
    #cccccc 1px,
    transparent 0
  );
  background-size: 24px 24px;
}

.adjust-dropdown-width .react-select-wrapper {
  width: 244px;
}

.adjust-dropdown-width2 .react-select-wrapper {
  width: 269px;
}

.disabled-card-inner span {
  color: #d0d5dd;
  pointer-events: none;
}

.disabled-card-inner img {
  filter: grayscale(1);
}

.remove-icon .select__indicator {
  display: none !important;
}

.react-datepicker-popper {
  position: absolute !important;
  top: 105% !important;
  left: 0 !important;
  transform: unset !important;
}

.react-datepicker__triangle {
  display: none;
}

.remove-below-div + input {
  height: 0;
  width: 0 !important;
}

.attendee-list-wrapper {
  position: absolute;
  z-index: 9999;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  background-color: #fff;
}

.remove-bottom-margin .react-select-wrapper {
  margin-bottom: 0;
}

/* Ipvijil start */

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 2px solid var(--color-blue-primary);
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-top: -9px;
  transition: all 0.2s ease;
  position: relative;
  z-index: 1;
}

input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 2px solid var(--color-blue-primary);
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  position: relative;
  z-index: 1;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  border: none;
  background: transparent;
  z-index: 0;
}

input[type="range"]::-moz-range-track {
  height: 2px;
  border: none;
  background: transparent;
  z-index: 0;
}

input[type="range"]:hover::-webkit-slider-thumb {
  transform: scale(1.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

input[type="range"]:hover::-moz-range-thumb {
  transform: scale(1.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 3px rgba(147, 51, 234, 0.2);
}

input[type="range"]:focus::-moz-range-thumb {
  box-shadow: 0 0 0 3px rgba(147, 51, 234, 0.2);
}

.nodeLabel p {
  font-family: "Inter Regular";
}

/* Ipvijil end */

/* RC Slider  */
.rc-slider-handle {
  border: 1px solid #dedede !important;
}

.rc-slider-handle:hover {
  border-color: #dedede !important;
}

.rc-slider-handle:active {
  border-color: #dedede !important;
}

.rc-slider-track,
.rc-slider-tracks {
  height: 7px !important;
  background-color: #1751d0 !important;
}

.disabled-rc-slider {
  background-color: #fbfbfb;
  padding: 8px 18px;
  border-radius: 8px;
  pointer-events: none;
  border: 1px solid #e4e7ec;
}

.heatmap-chart-styling .apexcharts-heatmap-rect:hover {
  /* filter: none; */
  /* filter: drop-shadow(2px 2px 1px #fff); */
  filter: brightness(0.93);
}

.heatmap-chart-styling .apexcharts-text.apexcharts-yaxis-label tspan,
.apexcharts-text.apexcharts-xaxis-label tspan {
  fill: #1751d0;
  font-weight: 600;
  font-size: 14px;
}

.heatmap-chart-styling .apexcharts-toolbar {
  top: 6px !important;
  right: 2px;
}

.heatmap-chart-styling .apexcharts-datalabel {
  font-family: "Inter", sans-serif !important;
  font-weight: 600 !important;
  font-size: 17px !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.5s ease-in-out;
}
